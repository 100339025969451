











import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import JobsSelectDialog from "./JobsSelectDialog.vue";

@Component({
  name: "ScoopDialog",
  components: {
    JobsSelectDialog,
  },
})
export default class extends Vue {
  @Prop({ required: true, default: [] })
  public readonly jobs!: [];

  @Prop({ required: true, default: "" })
  public readonly candidateId!: string;

  @Prop({ required: false, default: false })
  public readonly value!: boolean;

  onActionClick(selectedJobs: string[]) {
    const jobsString = selectedJobs.join(",");
    const url = `${process.env.VUE_APP_MAIN_SITE_URL}/Iam/EmployerDashboard/CandidateScoop?candidateId=${this.candidateId}&jobsId=${jobsString}&link=${window.location.href}`;
    window.location.href = url;
  }

  @Emit()
  input(value: boolean) {
    return value;
  }
}
