















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { InformationModel } from "@/models/common";
import PageContainerHeader from "@/views/shared/PageContainerHeader.vue";
import LocationMarker from "@/views/shared/LocationMarker.vue";
import SocialLinksList from "@/views/shared/SocialLinksList.vue";

@Component({
  name: "CandidateProfileHeader",
  components: {
    PageContainerHeader,
    LocationMarker,
    SocialLinksList,
  },
})
export default class extends Vue {
  @Prop({ required: true })
  public readonly candidateInfo!: InformationModel;

  get name() {
    return `${this.candidateInfo.firstName} ${this.candidateInfo.lastName}`;
  }

  get avatar() {
    const avatarUrl = this.candidateInfo.avatar?.resizedUrl;
    return avatarUrl ? avatarUrl : require("@/assets/no-avatar.svg");
  }
}
