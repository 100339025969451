











import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CandidateService } from "@/services/Search";
import { ECompensationType } from "@/models/enums";
import { SimilarCandidateModel } from "@/models/Candidate";
import { CandidateListItemModel, SpecialMarkModel } from "@/models/common";
import { MILITARY_VETERAN } from "@/constants/SpecialMarks";
import CandidatesList from "@/components/lists/CandidatesList/index.vue";

@Component({
  name: "SimilarCandidatesList",
  components: {
    CandidatesList,
  },
})
export default class extends Vue {
  @Prop({ required: true, default: "" })
  public readonly candidateId!: string;

  private items: CandidateListItemModel[] = [];

  private loading = false;

  private initLoading = false;

  private page = 1;

  private pageSize = 4;

  private showMore = true;

  async mounted() {
    try {
      this.initLoading = true;
      await this.load();
    } catch (e) {
      console.log("Similar candidates fetch error: ", e);
    } finally {
      this.initLoading = false;
    }
  }

  async load() {
    try {
      this.loading = true;
      const response = await CandidateService.similar(
        this.candidateId,
        this.page,
        this.pageSize
      );

      const mappedResponse = response.items.map((x: SimilarCandidateModel) => ({
        image: x.avatarURL,
        title: `${x.firstName} ${x.lastName}`,
        location: x.location,
        openToRelocation: x.relocation,
        link: x.profileLink,
        militaryVeteran: x.specialMarks.some(
          (y: SpecialMarkModel) => y.name === MILITARY_VETERAN && y.has
        ),
        salary:
          x.compensationType === ECompensationType.Salary
            ? `$${x.compensationSalary}/year`
            : `$${x.compensationHourly}/hour`,
      }));

      this.items = this.items.concat(mappedResponse);
      this.page = this.page + 1;

      if (response.currentPage === response.totalPages) {
        this.showMore = false;
      }
    } finally {
      this.loading = false;
    }
  }
}
