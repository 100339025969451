






import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "StickyMenu",
  components: {},
})
export default class extends Vue {}
