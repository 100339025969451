










import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import CardMenu from "@/components/common/CardMenu.vue";
import ECandidatePageMenuAction from "./models/enums/ECandidatePageMenuAction";

interface MenuItem {
  title: string;
  icon: string;
  iconColor: string;
  textColor?: string;
  separated?: boolean;
  action: ECandidatePageMenuAction;
  disabled?: boolean;
}

@Component({
  name: "CandidateMenu",
  components: {
    CardMenu,
  },
})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly canBeReported!: boolean;

  get menuItems(): MenuItem[] {
    return [
      {
        title: "Favorite",
        icon: "mdi-bookmark",
        iconColor: "$light-grey-color",
        action: ECandidatePageMenuAction.Save,
      },
      {
        title: "Save To...",
        icon: "mdi-bookmark",
        iconColor: "$light-grey-color",
        action: ECandidatePageMenuAction.SaveTo,
      },
      {
        title: "Notes",
        icon: "mdi-note",
        iconColor: "$light-grey-color",
        action: ECandidatePageMenuAction.Notes,
      },
      {
        title: "Scoop",
        icon: "mdi-note-multiple",
        iconColor: "$light-grey-color",
        action: ECandidatePageMenuAction.Scoop,
      },
      {
        title: "Forward",
        icon: "mdi-share",
        iconColor: "$light-grey-color",
        action: ECandidatePageMenuAction.Forward,
      },
      {
        title: "Pass",
        icon: "mdi-block-helper",
        iconColor: "$light-grey-color",
        action: ECandidatePageMenuAction.Pass,
      },
      {
        title: "Report",
        icon: "mdi-flag-variant",
        iconColor: "#BB4748",
        textColor: "#BB4748",
        separated: true,
        action: ECandidatePageMenuAction.Report,
        disabled: !this.canBeReported,
      },
    ];
  }

  @Emit()
  selected(value: ECandidatePageMenuAction) {
    return value;
  }

  @Emit()
  input(value: boolean) {
    return value;
  }
}
