var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"max-width":"900","value":_vm.value,"fullscreen":_vm.isMobile},on:{"input":_vm.input}},[_c('v-card',[_c('v-card-title',{staticClass:"dialog-title justify-space-between"},[_c('span',[_vm._v("Request a New Interview")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.input(false)}}},[_vm._v("x")])],1),_c('v-card-text',{staticClass:"d-flex flex-column gap-10"},[_c('validation-observer',{ref:"observer"},[_c('v-form',{staticClass:"pt-3"},[_c('dropdown',{attrs:{"outlined":"","dense":"","valueField":"id","textField":"title","rules":"required","placeholder":"Please select an Opportunity","items":_vm.opportunities,"loading":_vm.opportunitiesLoading},model:{value:(_vm.createInterviewData.opportunityId),callback:function ($$v) {_vm.$set(_vm.createInterviewData, "opportunityId", $$v)},expression:"createInterviewData.opportunityId"}},[_c('input-title',{attrs:{"slot":"title","icon":"mdi-briefcase","text":"Opportunity"},slot:"title"})],1),_c('autocomplete',{attrs:{"dense":"","outlined":"","multiple":"","chips":"","placeholder":"Add Co-Workers","valueField":"id","items":_vm.coworkerItems,"loading":_vm.coworkersLoading},model:{value:(_vm.createInterviewData.coWorkersId),callback:function ($$v) {_vm.$set(_vm.createInterviewData, "coWorkersId", $$v)},expression:"createInterviewData.coWorkersId"}},[_c('input-title',{attrs:{"slot":"title","icon":"mdi-account","text":"Co-Workers"},slot:"title"})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"4"}},[_c('date-time-picker',{attrs:{"name":"startDateTime","format":"MM/DD/YYYY h:mm A","type":"datetime","rules":{
                  required: true,
                  afterNow: {
                    timeZone: _vm.createInterviewData.timeZoneName,
                  },
                },"disabledDate":_vm.isStartDateDisabled,"disabledTime":_vm.isStartTimeDisabled},model:{value:(_vm.createInterviewData.startDateTime),callback:function ($$v) {_vm.$set(_vm.createInterviewData, "startDateTime", $$v)},expression:"createInterviewData.startDateTime"}},[_c('input-title',{attrs:{"slot":"title","icon":"mdi-calendar-blank","text":"Start"},slot:"title"})],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"4"}},[_c('date-time-picker',{attrs:{"name":"endDateTime","format":"MM/DD/YYYY h:mm A","type":"datetime","rules":{
                  required: true,
                  after: {
                    target: '@startDateTime',
                  },
                  afterNow: {
                    timeZone: _vm.createInterviewData.timeZoneName,
                  },
                },"disabledDate":_vm.isEndDateDisabled,"disabledTime":_vm.isEndTimeDisabled},model:{value:(_vm.createInterviewData.endDateTime),callback:function ($$v) {_vm.$set(_vm.createInterviewData, "endDateTime", $$v)},expression:"createInterviewData.endDateTime"}},[_c('input-title',{attrs:{"slot":"title","icon":"mdi-calendar-blank","text":"End"},slot:"title"})],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"4"}},[_c('autocomplete',{attrs:{"outlined":"","dense":"","valueField":"id","textField":"title","rules":"required","items":_vm.timeZones,"loading":_vm.timeZonesLoading},model:{value:(_vm.createInterviewData.timeZoneName),callback:function ($$v) {_vm.$set(_vm.createInterviewData, "timeZoneName", $$v)},expression:"createInterviewData.timeZoneName"}},[_c('input-title',{attrs:{"slot":"title","icon":"mdi-clock","text":"Time zone"},slot:"title"})],1)],1)],1),_c('div',{staticClass:"time-info-text pb-4"},[_c('v-icon',{staticClass:"time-info-icon"},[_vm._v("mdi-information")]),_vm._v(" An interview can not start earlier than the current date +10 min ")],1),_c('rich-text-editor',{model:{value:(_vm.createInterviewData.text),callback:function ($$v) {_vm.$set(_vm.createInterviewData, "text", $$v)},expression:"createInterviewData.text"}}),_c('checkbox',{attrs:{"dense":"","hide-details":"","label":"Record this interview"},model:{value:(_vm.createInterviewData.isRecorded),callback:function ($$v) {_vm.$set(_vm.createInterviewData, "isRecorded", $$v)},expression:"createInterviewData.isRecorded"}})],1)],1),_c('div',{staticClass:"dialog-actions"},[_c('v-btn',{staticClass:"action-button button--secondary",on:{"click":function($event){return _vm.input(false)}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"action-button button--success",attrs:{"loading":_vm.creatingInterview},on:{"click":_vm.createInterview}},[_vm._v(" Create ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }