




















import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { CoworkerModel } from "@/models/Candidate";
import { MediaModel } from "@/models/common";
import { CandidateService } from "@/services/Public";
import NewMessageDialog from "@/components/dialogs/NewMessageDialog.vue";
import MessageDialogModel from "../models/MessageDialogModel";

@Component({
  name: "MessageDialog",
  components: {
    NewMessageDialog,
  },
})
export default class MessageDialog extends Vue {
  @Prop({ required: true })
  public readonly value!: MessageDialogModel;

  private coworkers: CoworkerModel[] = [];

  private loading = false;

  private mediaLoading = false;

  private sending = false;

  private messageBody = "";

  private subject = "";

  private media: MediaModel[] = [];

  private selectedMedia: MediaModel | null = null;

  get recipients() {
    return this.coworkers.map((x) => ({
      text: `${x.firstName} ${x.lastName}`,
      value: x.userId,
    }));
  }

  @Watch("value")
  watchValue(value: MessageDialogModel | null) {
    if (value?.show) {
      const recipientName = `${this.value.recipientFirstName} ${this.value.recipientLastName}`;

      if (value.forwardLink) {
        this.messageBody = `<a href='${value.forwardLink}'>${recipientName}</a><br>`;
        this.refreshCoworkers();
      } else {
        this.coworkers.push({
          firstName: this.value.recipientFirstName,
          lastName: this.value.recipientLastName,
          userId: this.value.recipientId,
        });
      }
    } else {
      this.messageBody = "";
      this.subject = "";
      this.selectedMedia = null;
      this.coworkers = [];
      this.media = [];
    }
  }

  async refreshCoworkers() {
    try {
      this.loading = true;
      this.coworkers = await CandidateService.getCoworkers();
    } catch (e) {
      console.error("Coworkers fetch error:", e);
    } finally {
      this.loading = false;
    }
  }

  async refreshMedia() {
    try {
      this.mediaLoading = true;
      this.media = await CandidateService.getMedia();
    } catch (e) {
      console.log("Media fetch error:", e);
    } finally {
      this.mediaLoading = false;
    }
  }

  async send() {
    try {
      this.sending = true;
      await CandidateService.sendMessage(
        this.value.recipientId,
        this.subject,
        this.messageBody,
        this.selectedMedia?.id
      );
      this.input(false);
    } catch (e) {
      console.error("Message sending error:", e);
    } finally {
      this.sending = false;
    }
  }

  @Emit()
  input(value: boolean) {
    return {
      ...this.value,
      show: value,
    };
  }
}
