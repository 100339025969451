export function toIsoLocalTime(value: Date) {
  // https://stackoverflow.com/a/72809422
  if (value instanceof Date === false) value = new Date();
  const off = value.getTimezoneOffset() * -1;
  const del = value.getMilliseconds() ? "Z" : "."; // have milliseconds ?
  value = new Date(value.getTime() + off * 60000); // add or subtract time zone


  //console.log("OFF: " + off);
  //console.log("DEL: " + del);
  //console.log("VALUE: " + value);
  //console.log(value.toISOString().split(del)[0]);

  return (
    value.toISOString().split(del)[0]
  );
}
