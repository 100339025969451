






























import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { CandidateTemplate } from "@/models/Candidate";
import { CandidateService } from "@/services/Public";
import DialogWrapper from "@/views/shared/DialogWrapper.vue";

@Component({
  name: "InviteDialog",
  components: {
    DialogWrapper,
  },
})
export default class DialogWrapperss extends Vue {
  @Prop({ required: false, default: false })
  public readonly value!: boolean;

  @Prop({ required: true, default: "" })
  public readonly candidateId!: string;

  private templates: CandidateTemplate[] = [];

  private selectedTemplate = 0;

  private loading = false;

  private inviting = false;

  @Watch("value")
  watchValue(value: boolean) {
    if (value) {
      this.refreshTemplates();
    }
  }

  async refreshTemplates() {
    try {
      this.loading = true;
      this.templates = await CandidateService.getTemplates();
      this.selectedTemplate =
        this.templates.length > 0 ? this.templates[0].id : 0;
    } catch (e) {
      console.log("Templates fetching error: ", e);
    } finally {
      this.loading = false;
    }
  }

  async onActionClick() {
    try {
      this.inviting = true;
      await CandidateService.invite(this.candidateId, this.selectedTemplate);
      this.input(false);
    } catch (e) {
      console.log("Candidate inviting error: ", e);
    } finally {
      this.inviting = false;
    }
  }

  @Emit()
  input(value: boolean) {
    return value;
  }
}
