





























import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  name: "Autocomplete",
  components: {
    ValidationProvider,
  },
})
export default class extends Vue {
  @Prop({ required: false })
  public readonly value?: number;

  @Prop({ required: false })
  public readonly name?: string;

  @Prop({ required: false })
  public readonly rules?: string;

  @Prop({ required: false })
  public readonly vid?: string;

  @Prop({ required: false })
  public readonly items?: unknown[];

  @Prop({ required: false, default: "value" })
  public readonly valueField?: string;

  @Prop({ required: false, default: "text" })
  public readonly textField?: string;

  @Prop({ required: false, default: "" })
  public readonly placeholder?: string;

  @Prop({ required: false, default: false })
  public readonly loading!: boolean;

  @Prop({ required: false, default: false })
  public readonly disabled!: boolean;

  @Prop({ required: false, default: false })
  public readonly readonly!: boolean;

  @Prop({ required: false, default: false })
  public readonly chips!: boolean;

  @Prop({ required: false, default: false })
  public readonly dense!: boolean;

  @Prop({ required: false, default: false })
  public readonly multiple!: boolean;

  @Prop({ required: false, default: false })
  public readonly outlined!: boolean;

  @Prop({ required: false, default: () => [] })
  public readonly errorMessages!: unknown[];

  @Emit()
  input(value: number) {
    return value;
  }
}
