enum ECandidatePageMenuAction {
  Save,
  SaveTo,
  Notes,
  Scoop,
  Forward,
  Pass,
  Report,
}

export default ECandidatePageMenuAction;
