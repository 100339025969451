












import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { CandidateService } from "@/services/Public";
import JobsSelectDialog from "./JobsSelectDialog.vue";

@Component({
  name: "PassCandidateDialog",
  components: {
    JobsSelectDialog,
  },
})
export default class extends Vue {
  @Prop({ required: false, default: false })
  public readonly value!: boolean;

  @Prop({ required: true, default: "" })
  public readonly candidateId!: string;

  @Prop({ required: true, default: [] })
  public readonly jobs!: [];

  private loading = false;

  async onActionClick(selectedJobs: string[]) {
    try {
      this.loading = true;
      await CandidateService.passCandidate(this.candidateId, selectedJobs);
      this.input(false);
    } catch (e) {
      console.log("Pass candidate error: ", e);
    } finally {
      this.loading = false;
    }
  }

  @Emit()
  input(value: boolean) {
    return value;
  }
}
