
























































































import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import dateFormat from "dateformat";
import { InformationModel, SpecialMarkModel } from "@/models/common";
import { ECompensationType } from "@/models/enums";
import ApplyCardWrapper from "@/views/shared/ApplyCardWrapper.vue";
import CandidateMenu from "./CandidateMenu.vue";

@Component({
  name: "CandidateDetailsCard",
  components: {
    CandidateMenu,
    ApplyCardWrapper,
  },
})
export default class extends Vue {
  @Prop({ required: true })
  public readonly candidateInfo!: InformationModel;

  @Prop({ required: true, default: false })
  public readonly canBeInvited!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  public readonly canBeReported!: boolean;

  @Prop({ required: true, default: false })
  public readonly showActions!: boolean;

  get isVeteran() {
    return this.candidateInfo.specialMarks?.some(
      (x: SpecialMarkModel) => x.has && x.name === "Military Veteran"
    );
  }

  get lastLogin() {
    return dateFormat(this.candidateInfo.lastLogin, "mm/dd/yyyy");
  }

  get compensation() {
    return this.candidateInfo.compensationType === ECompensationType.Salary
      ? `$${this.candidateInfo.compensationSalary}/year`
      : `$${this.candidateInfo.compensationHourly}/hour`;
  }

  @Emit()
  menuItemSelected(value: unknown) {
    return value;
  }

  @Emit()
  message() {}

  @Emit()
  interview() {}

  @Emit()
  invite() {}
}
