


























import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import StickyMenu from "@/components/common/StickyMenu.vue";
import CandidateMenu from "./CandidateMenu.vue";

@Component({
  name: "CandidateStickyMenu",
  components: {
    StickyMenu,
    CandidateMenu,
  },
})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly canBeReported!: boolean;

  private showMenu = false;
  private overlay = false;

  onMenuToggle(value: boolean) {
    this.overlay = value;
  }

  onIntersect() {
    this.showMenu = !this.showMenu;
  }

  @Emit()
  menuItemSelected(value: unknown) {
    return value;
  }

  @Emit()
  message() {}

  @Emit()
  interview() {}
}
