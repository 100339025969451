
































import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import Checkbox from "@/components/inputs/Checkbox/index.vue";
import DialogWrapper from "@/views/shared/DialogWrapper.vue";

@Component({
  name: "JobsSelectDialog",
  components: {
    DialogWrapper,
    Checkbox,
  },
})
export default class extends Vue {
  @Prop({ required: true, default: [] })
  public readonly jobs!: [];

  @Prop({ required: true, default: "" })
  public readonly title!: string;

  @Prop({ required: true, default: "" })
  public readonly actionButtonText!: string;

  @Prop({ required: false, default: false })
  public readonly value!: boolean;

  @Prop({ required: false, default: false })
  public readonly loading!: boolean;

  private selectedJobs: string[] = [];

  @Watch("value")
  watchValue(value: boolean) {
    if (!value) {
      this.selectedJobs = [];
    }
  }

  isJobSelected(id: string) {
    return this.selectedJobs.some((x) => x === id);
  }

  onJobSelect(value: boolean, id: string) {
    if (value) {
      this.selectedJobs.push(id);
    } else {
      this.selectedJobs = this.selectedJobs.filter((x) => x !== id);
    }
  }

  @Emit()
  action() {
    return this.selectedJobs;
  }

  @Emit()
  input(value: boolean) {
    return value;
  }
}
