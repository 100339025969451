











































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { extend, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { CandidateService } from "@/services/Public";
import { TimeZoneModel } from "@/models/common";
import {
  CandidateInterviewOpportunityModel,
  CoworkerModel,
} from "@/models/Candidate";
import { CandidateCreateInterviewModel } from "@/models/requests/Candidate";
import Autocomplete from "@/components/inputs/Autocomplete/index.vue";
import Dropdown from "@/components/inputs/Dropdown/index.vue";
import RichTextEditor from "@/components/inputs/RichTextEditor/index.vue";
import DateTimePicker from "@/components/inputs/DateTimePicker/index.vue";
import Checkbox from "@/components/inputs/Checkbox/index.vue";
import InputTitle from "@/components/common/InputTitle.vue";
import { toIsoLocalTime } from "@/utils/dateHelper";
import { CandidateCreateInterviewDTO } from "@/models/requests/Candidate/CandidateCreateInterviewModel";
import {
  APP_LOCALE,
  INTERVIEW_MINIMUM_START_MINUTES,
} from "@/constants/GeneralConstants";

const ONE_MINUTE_MS = 60000;

function toTimeZoneDate(date: Date, timeZone?: string) {
  const options = typeof timeZone === "string" && timeZone ? { timeZone } : {};

  const dateString = date.toLocaleString(APP_LOCALE, options);

  return new Date(dateString);
}

extend("required", {
  ...required,
  message: `This field is required.`,
});

extend("after", {
  params: ["target"],
  validate(value, params: Record<string, any>) {
    if (!value || !params.target) {
      return false;
    }

    return value.getTime() >= params.target.getTime();
  },
  message: "Interview end must be after its start",
});

extend("afterNow", {
  params: ["timeZone"],
  validate(value, params: Record<string, any>) {
    const now = toTimeZoneDate(new Date(), params.timeZone);
    console.log(value.toLocaleString(), now.toLocaleString(), params.timeZone);
    return value.getTime() >= now.getTime();
  },
  message: "Interview can not be created in the past",
});

@Component({
  name: "InterviewDialog",
  components: {
    ValidationObserver,
    InputTitle,
    Autocomplete,
    Dropdown,
    RichTextEditor,
    DateTimePicker,
    Checkbox,
  },
})
export default class extends Vue {
  @Prop({ required: false, default: false })
  public readonly value!: boolean;

  @Prop({ required: true, default: "" })
  public readonly candidateId!: string;

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  private isMobile = false;

  private opportunities: CandidateInterviewOpportunityModel[] = [];

  private timeZones: TimeZoneModel[] = [];

  private coworkers: CoworkerModel[] = [];

  private timeZonesLoading = false;

  private opportunitiesLoading = false;

  private coworkersLoading = false;

  private creatingInterview = false;

  private createInterviewData = this.buildInterviewData();

  get nowDateForSelectedTimeZone() {
    const now = new Date(
      Date.now() + INTERVIEW_MINIMUM_START_MINUTES * ONE_MINUTE_MS
    );

    return this.createInterviewData.timeZoneName
      ? toTimeZoneDate(now, this.createInterviewData.timeZoneName)
      : new Date();
  }

  get minEndTime() {
    return new Date(
      this.nowDateForSelectedTimeZone.getTime() +
        INTERVIEW_MINIMUM_START_MINUTES * ONE_MINUTE_MS
    );
  }

  isStartDateDisabled(date: Date) {
    const startDate = new Date(this.nowDateForSelectedTimeZone);
    startDate.setDate(startDate.getDate() - 1);
    return date.toISOString() < startDate.toISOString();
  }

  isStartTimeDisabled(date: Date) {
    return date <= this.nowDateForSelectedTimeZone;
  }

  isEndDateDisabled(date: Date) {
    const startDate = new Date(this.createInterviewData.startDateTime);
    startDate.setDate(startDate.getDate() - 1);
    return date.toISOString() <= startDate.toISOString();
  }

  isEndTimeDisabled(date: Date) {
    return date <= this.minEndTime;
  }

  @Watch("createInterviewData.timeZoneName")
  onTimeZoneChange() {
    this.createInterviewData.startDateTime = this.nowDateForSelectedTimeZone;
    this.createInterviewData.endDateTime = new Date(
      this.nowDateForSelectedTimeZone.getTime() +
        INTERVIEW_MINIMUM_START_MINUTES * ONE_MINUTE_MS
    );
  }

  @Watch("createInterviewData.startDateTime")
  onEndDateTimeChange(newValue: Date) {
    if (this.isEndTimeDisabled(newValue)) {
      this.createInterviewData.endDateTime = this.minEndTime;
    }
  }

  get coworkerItems() {
    return this.coworkers.map((x) => ({
      id: x.userId,
      text: `${x.firstName} ${x.lastName}`,
    }));
  }

  @Watch("value")
  watchValue(value: boolean) {
    if (value) {
      this.createInterviewData = this.buildInterviewData();
      this.refreshTimeZones();
      this.refreshOpportunities();
      this.refreshCoworkers();
    } else {
      this.$refs.observer.reset();
    }
  }

  buildInterviewData(): CandidateCreateInterviewModel {
    return {
      candidateId: this.candidateId,
      opportunityId: "",
      coWorkersId: [],
      startDateTime: new Date(),
      endDateTime: new Date(),
      timeZoneName: "",
      isRecorded: false,
      text: "",
    };
  }

  onResize() {
    this.isMobile = window.innerWidth <= 800;
  }

  async refreshCoworkers() {
    try {
      this.coworkersLoading = true;
      this.coworkers = await CandidateService.getCoworkers();
    } catch (e) {
      console.log("Coworkers fetch error:", e);
    } finally {
      this.coworkersLoading = false;
    }
  }

  async refreshTimeZones() {
    try {
      this.timeZonesLoading = true;
      this.timeZones = await CandidateService.getTimeZones();
      if (this.timeZones && this.timeZones.length > 0) {
        let currentTimeZoneName = this.timeZones.find((x) => x.current)?.id;
        if (!currentTimeZoneName) {
          currentTimeZoneName = this.timeZones[0].id;
        }
        this.createInterviewData = {
          ...this.createInterviewData,
          timeZoneName: currentTimeZoneName,
        };
      }
    } catch (e) {
      console.log("TimeZones fetch error:", e);
    } finally {
      this.timeZonesLoading = false;
    }
  }

  async refreshOpportunities() {
    try {
      this.opportunitiesLoading = true;
      this.opportunities = await CandidateService.getOpportunities();
    } catch (e) {
      console.log("Opportunities fetch error:", e);
    } finally {
      this.opportunitiesLoading = false;
    }
  }

  createDTO(): CandidateCreateInterviewDTO {
    return {
      ...this.createInterviewData,
      // Need to send with browser timezone in ISO format
      startDateTime: toIsoLocalTime(this.createInterviewData.startDateTime),
      endDateTime: toIsoLocalTime(this.createInterviewData.endDateTime),
    };
  }

  async createInterview() {
    const formIsValid = await this.$refs.observer.validate();
    if (!formIsValid) return;

    try {
      this.creatingInterview = true;
      await CandidateService.createInterview(this.createDTO());
      this.input(false);
    } catch (e) {
      console.log("Interview inviting error:", e);
    } finally {
      this.creatingInterview = false;
    }
  }

  @Emit()
  input(value: boolean) {
    return value;
  }
}
